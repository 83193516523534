import React from "react"
import { Helmet } from "react-helmet"
import StandardLayout from "../layout/StandardLayout";

export default () => <StandardLayout>

        <Helmet>
            <title>Privacy Policy - Burke Shipping Services</title>
        </Helmet>

        <div className="u-pad-large u-pad-xlarge@m">
            <div className="l-wrap u-width-5">
                <h1 className="c-heading +h1 u-marg-bottom">Privacy Policy</h1>

                <p className="u-marg-bottom-large">If you (the “User”) continue to browse and use this Website, you are agreeing to our
                    (“Burke Shipping Ltd.”) Privacy Policy, which together with our Terms and Conditions,
                    govern Burke Shipping Ltd’s relationship with you when using this Website
                    (“burkeshipping.co.uk”).
                </p>


                <h3 className="u-marg-bottom">Non-personal identification information</h3>
                <p className="u-marg-bottom-large">We collect non-personal identification information about Users whenever they visit or
                    interact with our Website, which is stored on our web server logs. Non-personal
                    identification information may include logging the version of browser software being used,
                    the name of the Internet Service Provider, the locality and other technical information.
                </p>

                <h3 className="u-marg-bottom">Third party cookies</h3>

                <p className="u-marg-bottom-large">Third-party cookies are set where this Website uses outside services, for
                    the purpose of:
                </p>

                <ul className="u-marg-bottom-large">
                    <li>Allowing Burke Shipping Ltd. to store, generate and report aggregate visitor analytics
                        (such as where Users have come from, how long they spend on the Website and what they
                        look at) – we do this using StatCounter. A cookie is set for five years. StatCounter say
                        in their Privacy Policy that the information collected is “solely for statistical
                        purposes and is not used to identify individuals”.
                    </li>
                    <li>Where YouTube videos are embedded on this website, YouTube may also set their own
                        cookies, which you can read about on Google’s Privacy Policy. We do not access the
                        contents of these cookies.<br/>Users may choose to set their web browser software to
                            refuse cookies and for the majority of Users this should not cause any issues,
                            however please note there may be some functions on this Website that will not work
                            properly after cookies are disabled. There are many different web browser versions
                            in use, however for most users you will normally be able to block cookies by going
                            to the “Options” or “Preferences” menu in your software, then find the “Privacy”
                            settings.</li>
                </ul>

                <h3 className="u-marg-bottom">Third party websites</h3>
                <p className="u-marg-bottom-large">Users may find advertising or other content on our Website that link to the websites and
                    services of our partners, suppliers, advertisers, sponsors and other third parties. We do
                    not control the content that appears on these websites and are not responsible for the
                    practices employed by websites linked to or from our Website. In addition, these websites or
                    services, including their content and links, may be constantly changing. These websites and
                    services may have their own privacy policies and customer service policies. Browsing and
                    interaction on any other website, including websites which have a link to our Website, is
                    subject to that website’s own terms and policies.
                </p>

                <h3 className="u-marg-bottom">Changes to this privacy policy</h3>
                <p className="u-marg-bottom-large">Burke Shipping Ltd. has the discretion to update this privacy policy at any time. When we do, we will revise
                    the updated date at the bottom of this page and will notify our Users in our next email
                    newsletter. We encourage Users to frequently check this page for any changes to stay
                    informed about how we are helping to protect the personal information we collect. You
                    acknowledge and agree that it is your responsibility to review this Privacy Policy
                    periodically and become aware of modifications.
                </p>

                <h3 className="u-marg-bottom">Your acceptance of these terms</h3>
                <p className="u-marg-bottom-large">By using this Website, you signify your acceptance of this Privacy Policy. If you do not
                    agree to this Policy, please do not use our Website. Your continued use of this Website
                    following the publishing of changes to this Policy will be deemed your acceptance of those
                    changes.
                </p>

                <h3 className="u-marg-bottom">Contacting us</h3>
                <p className="u-marg-bottom">If you have any questions about this Privacy Policy, the practices of this site, or your
                    dealings with this site, please contact us at:</p>
                <p className="u-marg-bottom-large">
                    <br/>Burke Shipping Services Limited<br/>
                    Port Office<br/>
                    Lisahally Terminal<br/>
                    Londonderry<br/>
                    Northern Ireland<br/>
                    BT47 6FL<br/>
                    +44 (0) 28 7186 0990<br/>
                    or <a href="/contact">contact us</a>
                </p>

                <p><strong>Last revised:</strong> 4th June 2020</p>

            </div>
        </div>


        <div className="c-splitter"></div>
    </StandardLayout>